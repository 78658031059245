<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <free-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="false"
      @on-submit="createFreeCreate"
    >
    </free-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import MoneyService from '@admin/services/MoneyService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import FreeForm from './components/_From'

export default {
  name: 'FreeCreate',
  components: { PlaceholderForm, FreeForm },
  data () {
    return {
      pageTitle: '创建免单用户',
      model: {
        store_id: '',
        user_id: '',
        status: 1,
        stores: [],
        users: []
      }
    }
  },
  async created () {
    const { data } = await flatry(MoneyService.createFree())

    if (data) {
      this.model.stores = data.data.stores
      this.model.users = data.data.users
    }
  },
  methods: {
    async createFreeCreate (model, success, callback) {
      const { data } = await flatry(MoneyService.createFree(model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/free/update',
          query: { id: data.data }
        })

        success()
      }

      callback()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
