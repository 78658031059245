<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >

    <el-form-item label="启用用户">
        <el-select
          v-model="formModel.user_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in formModel.users"
            :key="item.id"
            :label="item.username"
            :value="item.id"
          >
          </el-option>
        </el-select>
    </el-form-item>

    <el-form-item label="启用商店">
        <el-select
          v-model="formModel.store_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in formModel.stores"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
    </el-form-item>

    <el-form-item label="启用状态">
        <el-radio-group v-model="formModel.status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="2">关闭</el-radio>
        </el-radio-group>
    </el-form-item>

    <el-form-item>
      <el-button size="medium" type="primary" native-type="submit" :loading="submitLoading">{{ submitText }}</el-button>
    </el-form-item>

  </el-form>
</template>

<script>
export default {
  name: 'FreeFrom',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formModel: null,
      formRules: null
    }
  },

  async created () {
    this.formModel = Object.assign(this.model)
  },

  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
